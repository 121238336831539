import request from '@/utils/request'
import ENDPOINT from '@/config/endpoint'

export function getDisplayModels(params) {
  return request({
    url: ENDPOINT.DISPLAY_MODELS,
    method: 'get',
    params
  })
}

export function createDisplayModel(data) {
  return request({
    url: ENDPOINT.DISPLAY_MODELS,
    method: 'post',
    data
  })
}

export function updateDisplayModel(data) {
  return request({
    url: ENDPOINT.DISPLAY_MODELS,
    method: 'patch',
    data
  })
}

export function deleteDisplayModel(id) {
  return request({
    url: ENDPOINT.DISPLAY_MODELS,
    method: 'delete',
    params: {
      id
    }
  })
}
