<template>
  <el-dialog
    top="80px"
    width="500px"
    class="adding-content-popup"
    title="Thêm nội dung trên banner"
    :visible="visible"
    :close-on-click-modal="false"
    @close="handleClose"
  >
    <el-form ref="form" :model="form" :rules="rules">
      <el-form-item label="Đối tượng hiển thị" prop="display_type">
        <el-select
          v-model="form.display_type"
          :disabled="!!content"
          class="w-100"
          @change="handleChangeDisplayType"
        >
          <el-option
            v-for="t in baseTypes"
            :key="t.value"
            :label="t.name"
            :value="t.value"
          >
          </el-option>
        </el-select>
      </el-form-item>
      <el-form-item
        label="Nội dung"
        prop="base_id"
      >
        <el-select
          v-model="form.base_id"
          class="w-100 set-height"
          filterable
          remote
          :placeholder="`Chọn ${getPlaceType(form.display_type)}`"
          :remote-method="searchObjectByType"
          :loading="loading"
          :disabled="callingAPI"
        >
          <el-option
            v-for="p in places"
            :key="p.id"
            :label="p.name"
            :value="p.id"
          >
          </el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="Gói hiển thị" prop="days">
        <el-select v-model="form.days" class="w-100">
          <el-option
            v-for="price in position.prices"
            :key="price.days"
            :label="`${price.days} Ngày (${numberWithDelimiter(price.price)}/ 1 Bài biết)`"
            :value="price.days"
          >
          </el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="Ngày bắt đầu hiển thị" prop="start_date">
        <el-date-picker
          v-model="form.start_date"
          type="datetime"
          class="w-100"
          format="dd-MM-yyyy"
          placeholder="Chọn ngày bắt đầu hiển thị"
          :disabled="callingAPI"
        />
      </el-form-item>
      <p v-if="endDate" class="text-primary">Thời gian hiển thị đến ngày {{ endDate }}</p>
      <el-form-item label="STT hiển thị" prop="ordered">
        <el-input
          v-model="form.ordered"
          :disabled="callingAPI"
          type="number"
          placeholder="Nhập stt hiển thị mong muốn"
        ></el-input>
      </el-form-item>
    </el-form>
    <span slot="footer" class="dialog-footer">
      <el-button :disabled="callingAPI" @click="handleClose">Hủy</el-button>
      <el-button type="primary" :loading="callingAPI" @click="handleSave">Lưu</el-button>
    </span>
  </el-dialog>
</template>

<script>
import { mapGetters } from 'vuex'
import { listPlaces } from '@/services/place'
import { getPlaceTypes, getPlaceType, numberWithDelimiter } from '@/utils/filters'
import { createDisplayModel, updateDisplayModel } from '@/services/display-model'
import dayjs from 'dayjs'

export default {
  name: 'ContentFormPopup',
  props: {
    visible: Boolean,
    content: Object,
    page: Object,
    position: Object,
    type: String
  },
  data() {
    return {
      loading: false,
      callingAPI: false,
      places: [],
      searchText: '',
      form: {
        display_type: this.type,
        days: null,
        base_id: null,
        start_date: dayjs(),
        ordered: null
      },
      rules: {
        days: [
          { required: true, message: 'Vui lòng chọn gói hiển thị', trigger: ['blur', 'change'] }
        ],
        base_id: [
          { required: true, message: 'Vui lòng chọn nội dung', trigger: ['blur', 'change'] }
        ],
        start_date: [
          { required: true, message: 'Vui lòng chọn ngày bắt đầu hiển thị', trigger: ['blur', 'change'] }
        ],
        ordered: [
          { required: true, message: 'STT không được để trống', trigger: ['blur', 'change'] }
        ]
      }
    }
  },
  computed: {
    ...mapGetters(['constant', 'language']),
    dataInput() {
      return {
        based_id: this.form.base_id,
        based_type: this.form.display_type === 'post' ? 'post' : 'place',
        display_type: this.form.display_type,
        display_from: dayjs(this.form.start_date).format('YYYY-MM-DDT00:00:00'),
        display_to: dayjs(this.form.start_date).add(this.form.days, 'days').format('YYYY-MM-DDT23:59:59'),
        display_position: `${this.position.id}`,
        ordered: this.form.ordered,
        page_id: this.page.label_short,
        banner_id: this.page.id
      }
    },
    baseTypes() {
      return getPlaceTypes(false).filter((p) => this.position.options.includes(p.value))
    },
    params() {
      return {
        locale: this.language,
        place_types: this.form.display_type,
        page: 1,
        per_page: 10,
        search_text: this.searchText
      }
    },
    endDate() {
      if (this.form.start_date && this.form.days) {
        return dayjs(this.form.start_date).add(this.form.days, 'days').format('DD-MM-YYYY')
      }
      return null
    }
  },
  beforeMount() {
    if (this.content) {
      const startDate = dayjs(this.content.display_from.split('/').reverse().join('/'))
      const endDate = dayjs(this.content.display_to.split('/').reverse().join('/'))
      this.places = [this.content.based_model]
      this.form = {
        display_type: this.content.display_type,
        days: endDate.diff(startDate, 'day'),
        base_id: this.content.based_id,
        start_date: startDate,
        ordered: `${this.content.ordered}`
      }
    } else {
      this.loadData()
    }
  },
  methods: {
    getPlaceType,
    numberWithDelimiter,

    loadData() {
      this.loading = true
      listPlaces(this.params).then((res) => {
        this.places = res.result
        this.loading = false
      }).catch(() => {
        this.loading = false
      })
    },

    handleSave() {
      this.callingAPI = true
      if (this.content) {
        updateDisplayModel({
          id: this.content.id,
          ...this.dataInput
        }).then(() => {
          this.$notify.success({
            title: 'Thành công',
            message: 'Cập nhật nội dung thành công'
          })
          this.$refs.form.resetFields()
          this.callingAPI = false
          this.$emit('reload')
          this.$emit('close')
        }).catch(() => {
          this.$notify.error({
            title: 'Thất bại',
            message: 'Không thể cập nhật nội dung'
          })
          this.callingAPI = false
        })
      } else {
        createDisplayModel(this.dataInput).then(() => {
          this.$notify.success({
            title: 'Thành công',
            message: 'Thêm nội dung thành công'
          })
          this.$refs.form.resetFields()
          this.callingAPI = false
          this.$emit('reload')
          this.$emit('close')
        }).catch(() => {
          this.$notify.error({
            title: 'Thất bại',
            message: 'Không thể thêm nội dung'
          })
          this.callingAPI = false
        })
      }
    },

    handleClose() {
      this.$refs.form.resetFields()
      this.$emit('close')
    },

    searchObjectByType(text) {
      this.searchText = text
      setTimeout(() => {
        this.loadData()
      }, 100)
    },

    handleChangeDisplayType() {
      this.loadData()
      this.form.base_id = null
    }
  }
}
</script>

<style lang="scss" scoped></style>
<style lang="scss">
.adding-content-popup {
  .el-dialog__body {
    max-height: 550px;
    overflow: auto;
  }
}
</style>
