<template>
  <el-table v-loading="loading" :data="data">
    <el-table-column label="STT" type="index" width="100" align="center"></el-table-column>
    <el-table-column label="Số ưu tiên" prop="ordered" width="100" align="center"></el-table-column>
    <el-table-column label="Tên" prop="name" min-width="180">
      <template slot-scope="scope">
        <router-link class="text-primary" :to="getToShowRouter(scope.row.based_model)">{{ scope.row.based_model.name }}</router-link>
      </template>
    </el-table-column>
    <el-table-column label="Ngày bắt đầu" prop="display_from" width="120" align="center">
      <template slot-scope="scope">
        {{ scope.row.display_from }}
      </template>
    </el-table-column>
    <el-table-column label="Ngày kết thúc" prop="display_to" width="120" align="center">
      <template slot-scope="scope">
        {{ scope.row.display_to }}
      </template>
    </el-table-column>
    <el-table-column label="Ngày tạo" prop="created_at" width="100" align="center">
      <template slot-scope="scope">
        {{ scope.row.created_at }}
      </template>
    </el-table-column>
    <el-table-column label="Thao tác" width="120" align="center" fixed="right">
      <template slot-scope="scope">
        <el-tooltip content="Sửa thông tin" placement="top">
          <el-button
            icon="el-icon-edit"
            circle
            type="primary"
            size="small"
            @click="$emit('edit', scope.row)"
          ></el-button>
        </el-tooltip>
        <el-tooltip content="Gỡ nội dung" placement="top">
          <el-button
            icon="el-icon-delete"
            circle
            type="danger"
            size="small"
            @click="$emit('delete', scope.row)"
          ></el-button>
        </el-tooltip>
      </template>
    </el-table-column>
  </el-table>
</template>

<script>
export default {
  name: 'ListContent',
  props: {
    data: Array,
    loading: Boolean,
    type: String,
    currentTab: Object
  },
  data() {
    return {
      imageDefaultUrl: '/default-image.jpeg'
    }
  },
  methods: {
    getToShowRouter(row) {
      const firstText = this.type.slice(0, 1).toUpperCase()
      const type = `${firstText}${this.type.slice(1, this.type.length)}`
      return { name: `${type}Info`, params: { id: row.id }}
    }
  }
}
</script>

<style lang="scss" scoped>
</style>
